import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ScheduleList() {
  const [schedules, setSchedules] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const baseUrl = 'https://service.pethub.intecsu.com'; // Reemplaza con tu URL base real
  const providerId = 1;

  const ruta = `${baseUrl}/api/v1/provider/${providerId}/schedules/`;

  useEffect(() => {
    const fetchSchedules = async () => {
      try {
        const response = await axios.get(ruta, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer 8|wF1l2VgaD1doryNs0yDB5tTSeHzCaDBW7Dm4ZgPw2542aca8` 
          }
        });
        setSchedules(response.data.data); 
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSchedules();
  }, [baseUrl, providerId]);

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <h2>Horarios del proveedor:</h2>
      <ul>
        {schedules.map((schedule) => (
          <li key={schedule.id}>
            {schedule.weekdayName}: {schedule.startHour} - {schedule.endHour}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ScheduleList;